<template>
  <div>
    <!--Banner Wrap Start-->
    <div class="iner_banner">
      <div class="container">
        <h5>FEES REGULATING AUTHORITY</h5>
      </div>
    </div>
    <!--Banner Wrap End-->

    <!--Iqoniq Content Start-->
    <div class="iq_content_wrap">
      <!--About Us START-->
      <section class="about-us-section">
        <div class="container">
          <div class="row">

            <div class="col-md-12 col-sm-6">
              <div class="text">
                <p>
                  <a class="text-dark" target="_blank" :href="approvalProposal">FEES REGULATING AUTHORITY - 2023-24</a>
                </p>
              </div>
            </div>

            <div class="col-md-12 col-sm-6">
              <div class="text">
                <p>
                  <a class="text-dark" target="_blank" :href="facultyDetails">FEES REGULATING AUTHORITY Faculty Details with salary- 2023-24</a>
                </p>
              </div>
            </div>

            <div class="col-md-12 col-sm-6">
              <div class="text">
                <p>
                  <a class="text-dark" target="_blank" :href="incomeAndExpenditure">FEES REGULATING AUTHORITY income and expenditure - 2023-24</a>
                </p>
              </div>
            </div>

          </div>
        </div>
      </section>
      <!--About Us End-->
    </div>

  </div>
</template>

<script>
export default {
  name: 'Fra',
  data () {
    return {
      approvalProposal: '/docs/FEES REGULATING AUTHORITY - 2023-24.pdf',
      facultyDetails: '/docs/FEES REGULATING AUTHORITY Faculty Details with salary- 2023-24.pdf',
      incomeAndExpenditure: '/docs/FEES REGULATING AUTHORITY income and expendature - 2023-24.pdf'
    }
  }
}
</script>

<style scoped>
.text-dark {
  color: #0f8f46;
  text-decoration: underline;
}
</style>
